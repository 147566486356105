<template>
  <div>
    <PocCardTable>
      <div class="flexBet">
        <div class="flex-1 pcth-title-left">PC Tracker</div>
        <div class="flex-1">
          <el-radio-group
            v-model="buttonActive"
            @change="changeButton"
            size="mini"
          >
            <el-radio-button :label="1">RF</el-radio-button>
            <el-radio-button :label="2">Commit</el-radio-button>
            <el-radio-button :label="3">备份历史</el-radio-button>
          </el-radio-group>
        </div>
        <div style="width: 200px"></div>
      </div>
    </PocCardTable>
    <PocCardTable style="margin-top: 15px">
      <div class="opeartionBox" id="opeartionBox">
        <div class="flexRow flexBetw">
          <div :class="['flexRow']">
            <div class="scopeItemBox" v-for="i in [0,1]" :key="'key' + i + Math.random()" @click="changeTab(i)">
              <div class="scopeItem">
                {{ tabLabel[i] }}
              </div>
              <div style="font-size: 0" class="guidaoactive" v-if="chooseTab == i">
                <img src="@/assets/img/guidaoactive.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div style="font-size: 0" class="guidao">
          <img class="guidaoImg" src="@/assets/img/guidao.png" alt="" />
        </div>
      </div>
      <PocTable
        ref="tTableRef"
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        stripe
        size="large"
        :max-height="999999"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        @row-click="goDetail"
        highlight-current-row
      >
        <el-table-column
          v-for="item in columnConfig"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.key === 'no' ? '60' : null"
          :min-width="item.width"
          :align="item.align"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column label="Operation" width="200" fixed="right" align="center" v-if="buttonActive !== 3">
          <template v-slot="scope">
            <el-button type="text" @click="goDetail(scope.row)">详情</el-button>
            <el-button type="text" v-show="scope.row.versionType != 'hrbpcnb'" @click.stop="refreshData(scope.row)">刷新</el-button>
            <el-button type="text" v-show="scope.row.versionType != 'hrbpcnb'" @click.stop="backup(scope.row)">备份</el-button>
            <el-button type="text" @click.stop="download(scope.row)">下载</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Operation" width="120" fixed="right" align="center" v-if="buttonActive === 3">
          <template v-slot="scope">
            <el-button type="text" @click.stop="downloadFileBack(scope.row)">下载</el-button>
          </template>
        </el-table-column>
      </PocTable>

    </PocCardTable>
  </div>
</template>
<script>

import { backup, backupCEO, ceoList, fileBackList, getYear, list, refresh, refreshCEO } from "@/api/dynamicPctracker";
import { getItem, setItem } from "@/utils/storage";
import { changeAllYear } from "@/views/salaryManagement/changeAllYear";
import { changeOp131Year } from "@/views/salaryManagement/editionData/ops131tableData";
import { changeOp151Year } from "@/views/salaryManagement/editionData/ops1512tableData";

export default {
  name:'pcTrackerTrends',
  mixins: [$PCommon.TableMixin],
  data(){
    return{
      buttonActive:1,
      loading:false,
      year:'2023',
      tabLabel:["By HFM","By CEO"],
      chooseTab:0,
      tableData:[],
      pageConfig:{
        pageNum:1,
        pageSize:10,
        total:0,
      },
      rfColumnConfig:[
        {
          key: 'year',
          name: 'Year',
          dataType: 'number', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'hfmCode',
          name: 'HFM Code',
          dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'updateTime',
          name: 'Update Time',
          dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
      ],
      rfColumnConfigCeo:[
        {
          key: 'year',
          name: 'Year',
          dataType: 'number', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'ceoName',
          name: 'CEO',
          dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'updateTime',
          name: 'Update Time',
          dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
      ],
      historyColumnConfig:[
        {
          key: 'bakMethod',
          name: '备份类型',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'year',
          name: 'Year',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'hfmCode',
          name: 'HFM Code',
          dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'createTime',
          name: '备份时间',
          dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'bakType',
          name: '备份方式',
          dataType: 'number', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
      ],
      historyColumnConfigCeo:[
        {
          key: 'bakMethod',
          name: '备份类型',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'year',
          name: 'Year',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'ceoName',
          name: 'CEO',
          dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'createTime',
          name: '备份时间',
          dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'bakType',
          name: '备份方式',
          dataType: 'number', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
      ],
    }
  },
  computed:{
    columnConfig(){
      if(this.chooseTab === 0){
        if(this.buttonActive === 3){
          return this.historyColumnConfig
        }
        return this.rfColumnConfig
      }else {
        if(this.buttonActive === 3){
          return this.historyColumnConfigCeo
        }
        return this.rfColumnConfigCeo
      }
    }
  },
  created() {
    let chooseTab = getItem('pc__chooseTab')
    if(chooseTab){
      this.chooseTab = Number(chooseTab)
    }
    getYear().then(res=>{
      if(res.code===200){
        this.year = res.data
        this.queryApi()
      }
    })
  },
  methods:{
    changeTab(val){
      this.chooseTab = val
      setItem("pc__chooseTab",val)
      this.queryApi()
    },
    changeButton(activeTab){
      this.buttonActive = activeTab
      this.manageShow = false //首页点击当前版本切换时只显示首页table
      if (this.buttonActive === 1) {
        this.editionType = 'indexEdition'
      } else if (this.buttonActive === 2) {
        this.editionType = 'allEdition'
      } else {
        this.editionType = 'hisEdition'
      }
      this.pageConfig.pageNum = 1
      this.queryApi()
    },
    refreshData(row){
      this.loading = true
      if(this.chooseTab === 0){
        refresh({id:row.id})
          .then(res=>{
            this.loading = false
            if(res.code === 200){
              this.$message.success(res.msg)
            }
          })
      }else {
        refreshCEO({id:row.id})
          .then(res=>{
            this.loading = false
            if(res.code === 200){
              this.$message.success(res.msg)
            }
          })
      }
    },
    async goDetail(row) {
      // if(row.submitStatus == '待提交') {
      //   const { code } = await this.$Api.salary.getVersionDetail(row.id);
      //   if(code != 200) return;
      // }
      if(this.buttonActive === 3){
        return
      }
      setItem('pcTrackerDetailIndex__yc', row.id)
      let paramsData = {
        id: row.id,
        fromDetailFlag: '',
        buttonActive: this.buttonActive,
        changeActive: this.changeActive,
        chooseTab: this.chooseTab,
        indexYear: row.year,
        hfmCode: row.hfmCode,
        // indexMonth: row.periodDate.slice(5, 7),
        // submitStatus: row.submitStatus
      }
      setItem('indexYear__yc', row.year)
      changeAllYear()
      changeOp131Year()
      changeOp151Year()
      if (this.buttonActive == 2 && this.changeActive == 2) {
        paramsData.fromDetailFlag = 'sumInsum'
      }
      if (this.buttonActive == 3) {
        console.log('aaaa', this.buttonActive)
        paramsData.summaryStatus = row.summaryStatus
        if (row.summaryStatus == '1') {
          paramsData.fromDetailFlag = 'sumInsum'
        }
      }
      this.$router.push({
        path: '/home/pcTracker/secondLevelPage',
        query: paramsData
      })
    },
    pageSizeChange(val) {
      this.pageConfig.pageSize = val
      this.queryApi()
    },
    pageChange(val) {
      this.pageConfig.pageNum = val
      this.queryApi()
    },
    queryApi(){
      if(this.buttonActive === 3){ // 备份
        let params = {
          year:this.year,
          searchType:this.chooseTab === 0 ? 'byhfm' : 'byceo',
          ...this.pageConfig
        }
        fileBackList(params).then(res=>{
          if(res.code === 200){
            this.tableData = res.data.list
            this.pageConfig.total = res.data.total
          }
        })
      }else { // 列表
        let params = {
          year:this.year,
          isRfVersion:this.buttonActive === 1,
          ...this.pageConfig
        }
        this.loading = true
        if(this.chooseTab === 0){
          list(params).then(res=>{
            this.loading = false
            if(res.code === 200){
              this.tableData = res.data.list
              this.pageConfig.total = res.data.total
            }
          })
        }else {
          ceoList(params).then(res=>{
            this.loading = false
            if(res.code === 200){
              this.tableData = res.data.list
              this.pageConfig.total = res.data.total
            }
          })
        }

      }
    },
    download(row){
      if(this.chooseTab === 0){
        this.$Api.staff.downloadDynamicPctrackerData({
          id:row.id,
          isRfVersion:this.buttonActive === 1,
          year:row.year,
        })
      }else {
        this.$Api.staff.downloadDynamicPctrackerDataByCEO({
          id:row.id,
          isRfVersion:this.buttonActive === 1,
          year:row.year,
        })
      }
    },
    downloadFileBack(row){
      this.$Api.staff.downloadDynamicPctrackerDataFileBack({
        id:row.id,
      })
    },
    backup(row){
      this.loading = true
      if(this.chooseTab === 0){
        backup({id:row.id}).then(res=>{
          this.loading = false
          if(res.code === 200){
            this.$message.success('备份中，预计耗时5分钟，请稍后在备份历史列表查看')
          }
        })
      }else {
        backupCEO({id:row.id}).then(res=>{
          this.loading = false
          if(res.code === 200){
            this.$message.success('备份中，预计耗时5分钟，请稍后在备份历史列表查看')
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pcTrackerTrends{
  .el-button{
   margin: 0 5px 0 0 !important;
  }
}
.opeartionBox {
  padding: 0;
  box-sizing: border-box;
  margin-bottom: 8px;
}
.scopeItem {
  width: 90px;
  color: #828282;
  font-size: 14px;
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
  cursor: pointer;
  overflow: hidden; //溢出隐藏
  text-overflow: ellipsis; //显示省略符号来代表被修剪的文本。
  white-space: nowrap; //文字不换行
}

.active {
  color: #303133;
  text-align: center;
}
.scopeContainer {
  margin-top: 23px;
}

.guidaoactive {
  padding-top: 8px;
  box-sizing: border-box;
  width: 90px;
  overflow: hidden;
}

.guidao {
  font-size: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  .guidaoImg {
    overflow: hidden;
  }
}
</style>
